<template>

<b-form @submit.prevent="submit">

  <b-form-group id="input-group-email" :label="t('email')" label-for="input-email">
    <b-form-input id="input-email" v-model="form.email" tabindex="1" required></b-form-input>
  </b-form-group>

  <b-form-group id="input-group-password" :label="t('password')" label-for="input-password">
    <b-form-input id="input-password" type="password" v-model="form.password" tabindex="2" required></b-form-input>
  </b-form-group>

  <b-form-checkbox id="checkbox-remember" v-model="form.remember" tabindex="3">
    {{ t('remember') }}
  </b-form-checkbox>

  <b-button-group class="mt-4">
    <b-button type="submit" variant="success" tabindex="4">{{ t('signin') }}</b-button>
    <b-button variant="link" to="recoverPassword">{{ t('recoverPassword') }}</b-button>
  </b-button-group>

</b-form>

</template>


<script>

export default {
  data: function () {
    return {
      form: {
        email: '',
        password: '',
        remember: false,
      },
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    submit: function() {
      this.$http.post('/login', this.form).then(response => {
        this.$store.commit('login', {
          'remember': this.form.remember,
          'token': response.data.token
        })
        window.location.reload()
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    }
  }
}

</script>

<style scoped lang="scss">

</style>
