<template>

<b-container class="vertical-space">

  <b-row>

    <b-col cols="auto" class="px-5">
      <SigninForm/>
    </b-col>

    <b-col class="px-5">
      <h1>Tous vos favoris internet</h1>
      <ul>
        <li>Entrez vos urls et faites sauvegarder</li>
        <li>Affichez-les en vignette et classez les par clic,visite, etc.</li>
        <li>Créez des dossiers</li>
        <li>Compatible avec tous les navigateurs, aucune application nécessaire</li>
      </ul>
      <b-button to="register">{{ t('register') }}</b-button>
    </b-col>

  </b-row>

</b-container>

</template>

<script>

import SigninForm from '@/components/SigninForm.vue'

export default {
  components: {
    SigninForm,
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
  }
}
</script>

<style scoped lang="scss">

</style>
